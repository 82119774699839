$link: #db2a2c;
$title-color: white;
$dropdown-divider-background-color: grey;
$modal-background-background-color: rgba(hsl(0, 0%, 4%), 0.1);

.title {
    background-color: $link;
}

.dropdown-content {
    max-height: 25rem;
    overflow: auto;
}

.panel {
    max-height: 25rem;
    overflow: auto;
}

.checkbox {
    input {
        margin-right: 0.25rem;
    }
}

.radio {
    margin-right: 1rem;

    input {
        margin-right: 0.25rem;
    }
}

#priceLabel {
    color: $link;
    font-size: 1.5rem;
}

@import "../../node_modules/bulma/bulma";

.select {
    max-width: 20rem;

    .contentTags {
        max-width: 12rem;
    }
}

.loader {
    width: 3rem;
    height: 3rem;
    border: 0.25rem transparent;
    border-top: 0.25rem solid $link;
    border-radius: 50%;
}

.navbar-item img {
    max-height: 3rem;
}

.rdt_TableHeadRow {
    font-size: 1rem;
}

.react-draggable {
    .modal-card-title {
        cursor: move;
    }
}
